import React, { useEffect, useState } from 'react';
import omit from 'lodash/omit';
import classNames from 'classnames';
import loadable from '@loadable/component';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  bool,
  func,
  node,
  array,
  shape,
  number,
  object,
  string,
  arrayOf,
  oneOfType,
} from 'prop-types';

import { richText } from '../../util/richText';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  displayDeliveryPickup,
  displayDeliveryShipping,
  displayPrice,
} from '../../util/configHelpers';
import {
  propTypes,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
  LINE_ITEM_NIGHT,
  LISTING_STATE_CLOSED,
  STOCK_MULTIPLE_ITEMS,
  STOCK_INFINITE_MULTIPLE_ITEMS,
} from '../../util/types';

import {
  isBookingProcess,
  isPurchaseProcess,
  INQUIRY_PROCESS_NAME,
  resolveLatestProcessName,
  getSupportedProcessesInfo,
} from '../../transactions/transaction';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { fetchAuthor } from '../../containers/ListingPage/ListingPage.duck';

import {
  H2,
  H4,
  AvatarMedium,
  ModalInMobile,
  PrimaryButton,
  InlineTextButton,
} from '../../components';

import css from './OrderPanel.module.css';

import { FaStar } from 'react-icons/fa';
import { ReactComponent as EmailSVG } from '../../assets/svg/email.svg';
import { ReactComponent as GlobeSVG } from '../../assets/svg/globe.svg';
import { ReactComponent as PhoneSVG } from '../../assets/svg/phone.svg';
import { ReactComponent as ShareSVG } from '../../assets/svg/share.svg';
import { ReactComponent as VerifiedSVG } from '../../assets/svg/verified.svg';
import { ReactComponent as WhatsAppSVG } from '../../assets/svg/whats-app.svg';

const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const TODAY = new Date();
const DESC_MAX_WORDS = 300;
const MODAL_BREAKPOINT = 279;
const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.
  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    showCurrencyMismatch = false,
  } = props;
  const { listingType, unitType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price

  return showCurrencyMismatch ? (
    <div className={css.priceContainerInCTA}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      <div className={css.perUnitInCTA}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  ) : (
    <div className={css.priceContainer}>
      <p className={css.price}>{formatMoney(intl, price)}</p>
      <div className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  );
};

const OrderPanel = props => {
  const [fullDesc, setFullDesc] = useState(false);

  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    reviewsData,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    callFetchAuthor,
    isCurrentListingRfq,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    payoutDetailsWarning,
    currentAuthor,
  } = props;
  const { avgRating = 0 } = reviewsData;
  const publicData = listing?.attributes?.publicData || {};
  const description = listing?.attributes?.description || '';

  const authorEmail = currentAuthor?.attributes?.email;
  const services = currentAuthor?.attributes?.profile?.publicData?.services || [];
  const companyName = currentAuthor?.attributes?.profile?.publicData?.companyName;
  const isPremiumVendor = currentAuthor?.attributes?.profile?.publicData?.isPremiumVendor;

  const callNumber = currentAuthor?.attributes?.profile?.publicData?.phoneNumber?.number;
  const callDialCode = currentAuthor?.attributes?.profile?.publicData?.phoneNumber?.dialCode;
  const vendorCallNumber = `${callDialCode}${callNumber}`;
  const whatsAppNumber =
    currentAuthor?.attributes?.profile?.publicData?.alternativePhoneNumber?.number;
  const whatsAppDialCode =
    currentAuthor?.attributes?.profile?.publicData?.alternativePhoneNumber?.dialCode;
  const vendorWhatsAppNumber = `${whatsAppDialCode}${whatsAppNumber}`;

  const websiteLink = currentAuthor?.attributes?.profile?.publicData?.websiteLink;
  const socialMediaLink = currentAuthor?.attributes?.profile?.publicData?.socialMediaLink;

  const {
    unitType,
    brandName,
    brandPhoto,
    listingType,
    servicesType,
    category,
    subCategory,
    subSubCategory,
    additionalDocuments,
    transactionProcessAlias = '',
  } = publicData || {};

  const categoryList = config?.categoryConfiguration?.categories;
  const result = {
    categories: categoryList.map(category => ({ id: category.id, name: category.name })),
    subCategories: [],
    subsubcategories: [],
  };

  categoryList.forEach(category => {
    category.subcategories.forEach(sub => {
      result.subCategories.push({ id: sub.id, name: sub.name });

      sub.subcategories.forEach(subSub => {
        result.subsubcategories.push({ id: subSub.id, name: subSub.name });
      });
    });
  });

  const categoryName = result?.categories?.filter(f => f?.id === category);
  const subCategoryName = result?.subCategories?.filter(f => f?.id === subCategory);
  const subSubCategoryName = result?.subsubcategories?.filter(f => f?.id === subSubCategory);

  const showCustomerNumber = publicData?.showNumber?.[0];
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;
  const trimmedDescription = fullDesc
    ? description
    : description?.length > DESC_MAX_WORDS
    ? description.slice(0, DESC_MAX_WORDS) + '...'
    : description;

  const price = listing?.attributes?.price;
  const productTitle = listing?.attributes?.title || 'Unknown';
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;
  const showPriceMissing = isPaymentProcess && !price;
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };

  const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const isBooking = isBookingProcess(processName);
  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone;
  const shouldHaveBookingDates =
    isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);

  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase && typeof currentStock === 'number';
  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;
  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);
  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};
  const listingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const displayShipping = displayDeliveryShipping(listingTypeConfig);
  const displayPickup = displayDeliveryPickup(listingTypeConfig);
  const allowOrdersOfMultipleItems = [STOCK_MULTIPLE_ITEMS, STOCK_INFINITE_MULTIPLE_ITEMS].includes(
    listingTypeConfig?.stockType
  );

  const authorDisplayName = userDisplayNameAsString(author, '');
  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;
  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  useEffect(() => {
    if (listing?.id?.uuid && author?.id?.uuid) {
      callFetchAuthor(author?.id?.uuid);
    }
  }, [listing?.id?.uuid]);

  return (
    <div className={classes}>
      <ModalInMobile
        usePortal
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        containerClassName={css.modalContainer}
        onClose={() => closeOrderModal(history, location)}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.priceAndRating}>
          {/* <PriceMaybe
            price={price}
            publicData={publicData}
            validListingTypes={validListingTypes}
            intl={intl}
            marketplaceCurrency={marketplaceCurrency}
          /> */}

          <section className={css.productName}>
            {richText(productTitle, {
              longWordClass: css.longWord,
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            })}
          </section>

          {!isCurrentListingRfq && (
            <section className={css.productRating}>
              <div>Rating:</div>
              <div>
                <FaStar />
                <span>{parseFloat(avgRating).toFixed(1)} / 5</span>
              </div>
            </section>
          )}
        </div>

        {brandPhoto || brandName ? (
          <div className={css.brandSection}>
            {brandPhoto && (
              <img className={css.brandImage} src={brandPhoto?.[0]?.location} alt="Preview" />
            )}
            <p className={css.brandName}>{brandName}</p>
          </div>
        ) : null}

        <p>{trimmedDescription}</p>

        {description?.length > DESC_MAX_WORDS && (
          <InlineTextButton onClick={e => setFullDesc(!fullDesc)} className={css.fullDesc}>
            {fullDesc ? 'Hide' : 'View'} Complete Details
          </InlineTextButton>
        )}

        {/* categories for listing */}
        <section className={css.categoryDetails}>
          <div>
            <span>Category:</span>
            <span>{categoryName?.[0]?.name}</span>
          </div>
          {subCategoryName?.length > 0 ? (
            <div>
              <span>Sub-Categories:</span>
              <span>{subCategoryName?.[0]?.name}</span>
            </div>
          ) : null}
          {subSubCategoryName?.length > 0 ? (
            <div>
              <span>Sub-Sub-Categories:</span>
              <span>{subSubCategoryName?.[0]?.name}</span>
            </div>
          ) : null}
        </section>

        {/* Services chips for listing */}
        {servicesType?.length ? (
          <div className={css.listingServiceChipContainer}>
            {/* {Array.from({ length: 12 }, () => servicesType).flat().map(service => ( */}
            {servicesType?.map(service => (
              <div key={service?.label}>{service?.label}</div>
            ))}
          </div>
        ) : null}

        {additionalDocuments?.length > 0 ? (
          <div className={css.additionalDocumentsContainer}>
            <H4 className={css.documentsHeading}>{'Documents'}</H4>
            <div className={css.additionalDocuments}>
              {additionalDocuments?.map(file => (
                <div className={css.additionalDocument} title={file?.name}>
                  <p
                    className={css.fileName}
                    onClick={() => window.open(file.location, '_blank')}
                    title={file.key?.split('/')[1]?.replace(/^\d+-/, '')}
                  >
                    {file?.name?.replace(/^\d+-/, '')}
                  </p>
                  <p className={css.fileSize}>{(+file.size / 1000).toFixed()} KB</p>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {/* <div className={css.orderHeading}>
          {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
          {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
        </div> */}

        {showPriceMissing ? (
          <PriceMissing />
        ) : showInvalidCurrency ? (
          <InvalidCurrency />
        ) : showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="OrderPanelBookingTimeForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
          />
        ) : showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="OrderPanelBookingDatesForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
          />
        ) : showProductOrderForm ? (
          <ProductOrderForm
            price={price}
            onSubmit={onSubmit}
            currentStock={currentStock}
            formId="OrderPanelProductOrderForm"
            marketplaceCurrency={marketplaceCurrency}
            allowOrdersOfMultipleItems={allowOrdersOfMultipleItems}
            pickupEnabled={pickupEnabled && displayPickup}
            shippingEnabled={shippingEnabled && displayShipping}
            displayDeliveryMethod={displayPickup || displayShipping}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onContactUser={onContactUser}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
          />
        ) : showInquiryForm ? (
          <div className={css.inquiry}>
            <InquiryWithoutPaymentForm
              onSubmit={onSubmit}
              isOwnListing={isOwnListing}
              formId="OrderPanelInquiryForm"
            />
          </div>
        ) : !isKnownProcess ? (
          <p className={css.errorSidebar}>
            <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
          </p>
        ) : null}

        <section className={css.authorSection}>
          {!isCurrentListingRfq ? (
            <div className={css.author}>
              <div className={css.authorAvatar}>
                <AvatarMedium user={author} className={css.providerAvatar} />
              </div>

              <div className={css.authorInformation}>
                {/* Business Name */}
                <div
                  className={css.authorCompany}
                  onClick={() => {
                    const params = { id: author?.id?.uuid };
                    history.push(
                      createResourceLocatorString('ProfilePage', routeConfiguration, params, {})
                    );
                  }}
                >
                  <span>{companyName}</span>
                  {isPremiumVendor && (
                    <React.Fragment>
                      <VerifiedSVG />
                      <span className={css.authorIsVerified}>Verified</span>
                    </React.Fragment>
                  )}
                </div>

                {/* Provider Name */}
                {/* <div className={css.authorName}>{authorDisplayName}</div> */}

                {/* Chips */}
                {services && (
                  <div className={css.serviceChipContainer}>
                    {/* {Array.from({ length: 12 }, () => services).flat().map(service => ( */}
                    {services?.map(service => (
                      <div key={service?.label}>{service?.label}</div>
                    ))}
                  </div>
                )}

                {/* Email */}
                <div className={css.authorEmail}>
                  <EmailSVG />
                  {authorEmail ? (
                    <a className={css.anchorTag} href={`mailto:${authorEmail}`}>
                      {authorEmail}
                    </a>
                  ) : (
                    <span>Loading Email Address...</span>
                  )}
                </div>

                {/* Phone */}
                {callDialCode && callNumber ? (
                  <div className={css.authorCall}>
                    <PhoneSVG />
                    <a className={css.anchorTag} href={`tel:${vendorCallNumber}`}>
                      {vendorCallNumber}
                    </a>
                  </div>
                ) : null}

                {/* WhatsApp */}
                {whatsAppDialCode && whatsAppNumber ? (
                  <div className={css.authorWhatsApp}>
                    <WhatsAppSVG />
                    <a
                      className={css.anchorTag}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://api.whatsapp.com/send?phone=${vendorWhatsAppNumber}&text=${encodeURIComponent(
                        `I'm interested in your listing (${productTitle} - ${brandName}) on ${marketplaceName}. Please let me know if you're available for a conversation. Thank you!`
                      )}`}
                    >
                      {vendorWhatsAppNumber}
                    </a>
                  </div>
                ) : null}

                {/* Website Link */}
                {websiteLink ? (
                  <div className={css.authorWhatsApp}>
                    <GlobeSVG />
                    <a
                      className={css.anchorTag}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={websiteLink}
                    >
                      {websiteLink}
                    </a>
                  </div>
                ) : null}

                {/* Social Media Link */}
                {socialMediaLink ? (
                  <div className={css.authorWhatsApp}>
                    <ShareSVG />
                    <a
                      className={css.anchorTag}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={socialMediaLink}
                    >
                      {socialMediaLink}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <div className={css.author}>
              <div className={css.authorAvatar}>
                <AvatarMedium
                  user={author}
                  className={css.providerAvatar}
                  disableProfileLink={isCurrentListingRfq}
                />
              </div>

              <div className={css.authorInformation}>
                {/* Provider Name */}
                <div className={css.customerAsAuthorName}>
                  <span>{authorDisplayName}</span>
                </div>

                {/* Email */}
                <div className={css.authorEmail}>
                  <EmailSVG />
                  {authorEmail ? (
                    <a href={`mailto:${authorEmail}`}>{authorEmail}</a>
                  ) : (
                    <span>Loading Email Address...</span>
                  )}
                </div>

                {/* Phone */}
                {showCustomerNumber && callDialCode && callNumber ? (
                  <div className={css.authorCall}>
                    <PhoneSVG />
                    <a href={`tel:${vendorCallNumber}`}>{vendorCallNumber}</a>
                  </div>
                ) : null}

                {/* WhatsApp */}
                {showCustomerNumber && whatsAppDialCode && whatsAppNumber ? (
                  <div className={css.authorWhatsApp}>
                    <WhatsAppSVG />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://api.whatsapp.com/send?phone=${vendorWhatsAppNumber}&text=${encodeURIComponent(
                        `I'm interested in your listing (${productTitle} - ${brandName}) on ${marketplaceName}. Please let me know if you're available for a conversation. Thank you!`
                      )}`}
                    >
                      {vendorWhatsAppNumber}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </section>
      </ModalInMobile>

      <div className={css.openOrderForm}>
        {/* <PriceMaybe
          intl={intl}
          price={price}
          showCurrencyMismatch
          publicData={publicData}
          validListingTypes={validListingTypes}
          marketplaceCurrency={marketplaceCurrency}
        /> */}

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <PrimaryButton
            onClick={handleSubmit(
              isOwnListing,
              isClosed,
              showInquiryForm,
              onSubmit,
              history,
              location
            )}
            disabled={isOutOfStock || isOwnListing}
          >
            {isBooking ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
            ) : isOutOfStock ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
            ) : isPurchase ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
            ) : isOwnListing ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessageDisabled" />
            ) : (
              <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
            )}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  payoutDetailsWarning: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  payoutDetailsWarning: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentAuthor } = state.ListingPage;
  return { currentAuthor };
};

const mapDispatchToProps = dispatch => ({
  callFetchAuthor: authorId => dispatch(fetchAuthor(authorId)),
});

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderPanel);
