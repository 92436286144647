/* different signup route (tab) */
export const VENDOR_SIGNUP = 'vendor-signup';
export const CUSTOMER_SIGNUP = 'customer-signup';
/* different signup route (tab) */

/* different profile settings route (tab) */
export const PROFILE_DEFAULT = 'default';
export const PROFILE_REVIEWS = 'reviews';
export const PROFILE_LISTINGS = 'listings';
export const PROFILE_CERTIFICATES = 'certificates';
/* different profile settings route (tab) */

export const phoneDialCodeData = [
  {
    name: {
      en: 'Afghanistan',
      fr: 'Afghanistan',
    },
    iso2: 'AF',
    iso3: 'AFG',
    dialCode: '+93',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Albania',
      fr: 'Albanie',
    },
    iso2: 'AL',
    iso3: 'ALB',
    dialCode: '+355',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Algeria',
      fr: 'Algérie',
    },
    iso2: 'DZ',
    iso3: 'DZA',
    dialCode: '+213',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Andorra',
      fr: 'Andorre',
    },
    iso2: 'AD',
    iso3: 'AND',
    dialCode: '+376',
    maxDigit: 6,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Angola',
      fr: 'Angola',
    },
    iso2: 'AO',
    iso3: 'AGO',
    dialCode: '+244',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Antigua and Barbuda',
      fr: 'Antigua-et-Barbuda',
    },
    iso2: 'AG',
    iso3: 'ATG',
    dialCode: '+1-268',
    maxDigit: 7,
    nddPrefix: '1',
  },
  {
    name: {
      en: 'Argentina',
      fr: 'Argentine',
    },
    iso2: 'AR',
    iso3: 'ARG',
    dialCode: '+54',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Armenia',
      fr: 'Arménie',
    },
    iso2: 'AM',
    iso3: 'ARM',
    dialCode: '+374',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Australia',
      fr: 'Australie',
    },
    iso2: 'AU',
    iso3: 'AUS',
    dialCode: '+61',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Austria',
      fr: 'Autriche',
    },
    iso2: 'AT',
    iso3: 'AUT',
    dialCode: '+43',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Azerbaijan',
      fr: 'Azerbaïdjan',
    },
    iso2: 'AZ',
    iso3: 'AZE',
    dialCode: '+994',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Bahamas',
      fr: 'Bahamas',
    },
    iso2: 'BS',
    iso3: 'BHS',
    dialCode: '+1-242',
    maxDigit: 7,
    nddPrefix: '1',
  },
  {
    name: {
      en: 'Bahrain',
      fr: 'Bahreïn',
    },
    iso2: 'BH',
    iso3: 'BHR',
    dialCode: '+973',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Bangladesh',
      fr: 'Bangladesh',
    },
    iso2: 'BD',
    iso3: 'BGD',
    dialCode: '+880',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Barbados',
      fr: 'Barbade',
    },
    iso2: 'BB',
    iso3: 'BRB',
    dialCode: '+1-246',
    maxDigit: 7,
    nddPrefix: '1',
  },
  {
    name: {
      en: 'Belarus',
      fr: 'Bélarus',
    },
    iso2: 'BY',
    iso3: 'BLR',
    dialCode: '+375',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Belgium',
      fr: 'Belgique',
    },
    iso2: 'BE',
    iso3: 'BEL',
    dialCode: '+32',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Belize',
      fr: 'Belize',
    },
    iso2: 'BZ',
    iso3: 'BLZ',
    dialCode: '+501',
    maxDigit: 7,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Benin',
      fr: 'Bénin',
    },
    iso2: 'BJ',
    iso3: 'BEN',
    dialCode: '+229',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Bhutan',
      fr: 'Bhoutan',
    },
    iso2: 'BT',
    iso3: 'BTN',
    dialCode: '+975',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Bolivia',
      fr: 'Bolivie',
    },
    iso2: 'BO',
    iso3: 'BOL',
    dialCode: '+591',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Bosnia and Herzegovina',
      fr: 'Bosnie-Herzégovine',
    },
    iso2: 'BA',
    iso3: 'BIH',
    dialCode: '+387',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Botswana',
      fr: 'Botswana',
    },
    iso2: 'BW',
    iso3: 'BWA',
    dialCode: '+267',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Brazil',
      fr: 'Brésil',
    },
    iso2: 'BR',
    iso3: 'BRA',
    dialCode: '+55',
    maxDigit: 11,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Brunei',
      fr: 'Brunei',
    },
    iso2: 'BN',
    iso3: 'BRN',
    dialCode: '+673',
    maxDigit: 7,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Bulgaria',
      fr: 'Bulgarie',
    },
    iso2: 'BG',
    iso3: 'BGR',
    dialCode: '+359',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Burkina Faso',
      fr: 'Burkina Faso',
    },
    iso2: 'BF',
    iso3: 'BFA',
    dialCode: '+226',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Burundi',
      fr: 'Burundi',
    },
    iso2: 'BI',
    iso3: 'BDI',
    dialCode: '+257',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Cabo Verde',
      fr: 'Cap-Vert',
    },
    iso2: 'CV',
    iso3: 'CPV',
    dialCode: '+238',
    maxDigit: 7,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Cambodia',
      fr: 'Cambodge',
    },
    iso2: 'KH',
    iso3: 'KHM',
    dialCode: '+855',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Cameroon',
      fr: 'Cameroun',
    },
    iso2: 'CM',
    iso3: 'CMR',
    dialCode: '+237',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Canada',
      fr: 'Canada',
    },
    iso2: 'CA',
    iso3: 'CAN',
    dialCode: '+1',
    maxDigit: 10,
    nddPrefix: '1',
  },
  {
    name: {
      en: 'Central African Republic',
      fr: 'République Centrafricaine',
    },
    iso2: 'CF',
    iso3: 'CAF',
    dialCode: '+236',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Chad',
      fr: 'Tchad',
    },
    iso2: 'TD',
    iso3: 'TCD',
    dialCode: '+235',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Chile',
      fr: 'Chili',
    },
    iso2: 'CL',
    iso3: 'CHL',
    dialCode: '+56',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'China',
      fr: 'Chine',
    },
    iso2: 'CN',
    iso3: 'CHN',
    dialCode: '+86',
    maxDigit: 11,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Colombia',
      fr: 'Colombie',
    },
    iso2: 'CO',
    iso3: 'COL',
    dialCode: '+57',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Comoros',
      fr: 'Comores',
    },
    iso2: 'KM',
    iso3: 'COM',
    dialCode: '+269',
    maxDigit: 7,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Congo (Congo-Brazzaville)',
      fr: 'Congo (Congo-Brazzaville)',
    },
    iso2: 'CG',
    iso3: 'COG',
    dialCode: '+242',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Congo (Democratic Republic)',
      fr: 'Congo (République Démocratique)',
    },
    iso2: 'CD',
    iso3: 'COD',
    dialCode: '+243',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Costa Rica',
      fr: 'Costa Rica',
    },
    iso2: 'CR',
    iso3: 'CRI',
    dialCode: '+506',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Croatia',
      fr: 'Croatie',
    },
    iso2: 'HR',
    iso3: 'HRV',
    dialCode: '+385',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Cuba',
      fr: 'Cuba',
    },
    iso2: 'CU',
    iso3: 'CUB',
    dialCode: '+53',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Cyprus',
      fr: 'Chypre',
    },
    iso2: 'CY',
    iso3: 'CYP',
    dialCode: '+357',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Czech Republic',
      fr: 'République tchèque',
    },
    iso2: 'CZ',
    iso3: 'CZE',
    dialCode: '+420',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Denmark',
      fr: 'Danemark',
    },
    iso2: 'DK',
    iso3: 'DNK',
    dialCode: '+45',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Djibouti',
      fr: 'Djibouti',
    },
    iso2: 'DJ',
    iso3: 'DJI',
    dialCode: '+253',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Dominica',
      fr: 'Dominique',
    },
    iso2: 'DM',
    iso3: 'DMA',
    dialCode: '+1-767',
    maxDigit: 7,
    nddPrefix: '1',
  },
  {
    name: {
      en: 'Dominican Republic',
      fr: 'République Dominicaine',
    },
    iso2: 'DO',
    iso3: 'DOM',
    dialCode: '+1-809',
    maxDigit: 10,
    nddPrefix: '1',
  },
  {
    name: {
      en: 'Ecuador',
      fr: 'Équateur',
    },
    iso2: 'EC',
    iso3: 'ECU',
    dialCode: '+593',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Egypt',
      fr: 'Égypte',
    },
    iso2: 'EG',
    iso3: 'EGY',
    dialCode: '+20',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'El Salvador',
      fr: 'Salvador',
    },
    iso2: 'SV',
    iso3: 'SLV',
    dialCode: '+503',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Equatorial Guinea',
      fr: 'Guinée équatoriale',
    },
    iso2: 'GQ',
    iso3: 'GNQ',
    dialCode: '+240',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Eritrea',
      fr: 'Érythrée',
    },
    iso2: 'ER',
    iso3: 'ERI',
    dialCode: '+291',
    maxDigit: 7,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Estonia',
      fr: 'Estonie',
    },
    iso2: 'EE',
    iso3: 'EST',
    dialCode: '+372',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Eswatini',
      fr: 'Eswatini',
    },
    iso2: 'SZ',
    iso3: 'SWZ',
    dialCode: '+268',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Ethiopia',
      fr: 'Éthiopie',
    },
    iso2: 'ET',
    iso3: 'ETH',
    dialCode: '+251',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Fiji',
      fr: 'Fidji',
    },
    iso2: 'FJ',
    iso3: 'FJI',
    dialCode: '+679',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Finland',
      fr: 'Finlande',
    },
    iso2: 'FI',
    iso3: 'FIN',
    dialCode: '+358',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'France',
      fr: 'France',
    },
    iso2: 'FR',
    iso3: 'FRA',
    dialCode: '+33',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Gabon',
      fr: 'Gabon',
    },
    iso2: 'GA',
    iso3: 'GAB',
    dialCode: '+241',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Gambia',
      fr: 'Gambie',
    },
    iso2: 'GM',
    iso3: 'GMB',
    dialCode: '+220',
    maxDigit: 7,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Georgia',
      fr: 'Géorgie',
    },
    iso2: 'GE',
    iso3: 'GEO',
    dialCode: '+995',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Germany',
      fr: 'Allemagne',
    },
    iso2: 'DE',
    iso3: 'DEU',
    dialCode: '+49',
    maxDigit: 11,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Ghana',
      fr: 'Ghana',
    },
    iso2: 'GH',
    iso3: 'GHA',
    dialCode: '+233',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Greece',
      fr: 'Grèce',
    },
    iso2: 'GR',
    iso3: 'GRC',
    dialCode: '+30',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Grenada',
      fr: 'Grenade',
    },
    iso2: 'GD',
    iso3: 'GRD',
    dialCode: '+1-473',
    maxDigit: 7,
    nddPrefix: '1',
  },
  {
    name: {
      en: 'Guatemala',
      fr: 'Guatemala',
    },
    iso2: 'GT',
    iso3: 'GTM',
    dialCode: '+502',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Guinea',
      fr: 'Guinée',
    },
    iso2: 'GN',
    iso3: 'GIN',
    dialCode: '+224',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Guinea-Bissau',
      fr: 'Guinée-Bissau',
    },
    iso2: 'GW',
    iso3: 'GNB',
    dialCode: '+245',
    maxDigit: 7,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Guyana',
      fr: 'Guyane',
    },
    iso2: 'GY',
    iso3: 'GUY',
    dialCode: '+592',
    maxDigit: 7,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Haiti',
      fr: 'Haïti',
    },
    iso2: 'HT',
    iso3: 'HTI',
    dialCode: '+509',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Honduras',
      fr: 'Honduras',
    },
    iso2: 'HN',
    iso3: 'HND',
    dialCode: '+504',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Hungary',
      fr: 'Hongrie',
    },
    iso2: 'HU',
    iso3: 'HUN',
    dialCode: '+36',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Iceland',
      fr: 'Islande',
    },
    iso2: 'IS',
    iso3: 'ISL',
    dialCode: '+354',
    maxDigit: 7,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'India',
      fr: 'Inde',
    },
    iso2: 'IN',
    iso3: 'IND',
    dialCode: '+91',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Indonesia',
      fr: 'Indonésie',
    },
    iso2: 'ID',
    iso3: 'IDN',
    dialCode: '+62',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Iran',
      fr: 'Iran',
    },
    iso2: 'IR',
    iso3: 'IRN',
    dialCode: '+98',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Iraq',
      fr: 'Irak',
    },
    iso2: 'IQ',
    iso3: 'IRQ',
    dialCode: '+964',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Ireland',
      fr: 'Irlande',
    },
    iso2: 'IE',
    iso3: 'IRL',
    dialCode: '+353',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Israel',
      fr: 'Israël',
    },
    iso2: 'IL',
    iso3: 'ISR',
    dialCode: '+972',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Italy',
      fr: 'Italie',
    },
    iso2: 'IT',
    iso3: 'ITA',
    dialCode: '+39',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Jamaica',
      fr: 'Jamaïque',
    },
    iso2: 'JM',
    iso3: 'JAM',
    dialCode: '+1-876',
    maxDigit: 7,
    nddPrefix: '1',
  },
  {
    name: {
      en: 'Japan',
      fr: 'Japon',
    },
    iso2: 'JP',
    iso3: 'JPN',
    dialCode: '+81',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Jordan',
      fr: 'Jordanie',
    },
    iso2: 'JO',
    iso3: 'JOR',
    dialCode: '+962',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Kazakhstan',
      fr: 'Kazakhstan',
    },
    iso2: 'KZ',
    iso3: 'KAZ',
    dialCode: '+7',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Kenya',
      fr: 'Kenya',
    },
    iso2: 'KE',
    iso3: 'KEN',
    dialCode: '+254',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Kiribati',
      fr: 'Kiribati',
    },
    iso2: 'KI',
    iso3: 'KIR',
    dialCode: '+686',
    maxDigit: 7,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Korea (North)',
      fr: 'Corée du Nord',
    },
    iso2: 'KP',
    iso3: 'PRK',
    dialCode: '+850',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Korea (South)',
      fr: 'Corée du Sud',
    },
    iso2: 'KR',
    iso3: 'KOR',
    dialCode: '+82',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Kuwait',
      fr: 'Koweït',
    },
    iso2: 'KW',
    iso3: 'KWT',
    dialCode: '+965',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Kyrgyzstan',
      fr: 'Kirghizistan',
    },
    iso2: 'KG',
    iso3: 'KGZ',
    dialCode: '+996',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Laos',
      fr: 'Laos',
    },
    iso2: 'LA',
    iso3: 'LAO',
    dialCode: '+856',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Latvia',
      fr: 'Lettonie',
    },
    iso2: 'LV',
    iso3: 'LVA',
    dialCode: '+371',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Lebanon',
      fr: 'Liban',
    },
    iso2: 'LB',
    iso3: 'LBN',
    dialCode: '+961',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Lesotho',
      fr: 'Lesotho',
    },
    iso2: 'LS',
    iso3: 'LSO',
    dialCode: '+266',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Liberia',
      fr: 'Liberia',
    },
    iso2: 'LR',
    iso3: 'LBR',
    dialCode: '+231',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Libya',
      fr: 'Libye',
    },
    iso2: 'LY',
    iso3: 'LBY',
    dialCode: '+218',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Liechtenstein',
      fr: 'Liechtenstein',
    },
    iso2: 'LI',
    iso3: 'LIE',
    dialCode: '+423',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Lithuania',
      fr: 'Lituanie',
    },
    iso2: 'LT',
    iso3: 'LTU',
    dialCode: '+370',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Luxembourg',
      fr: 'Luxembourg',
    },
    iso2: 'LU',
    iso3: 'LUX',
    dialCode: '+352',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Madagascar',
      fr: 'Madagascar',
    },
    iso2: 'MG',
    iso3: 'MDG',
    dialCode: '+261',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Malawi',
      fr: 'Malawi',
    },
    iso2: 'MW',
    iso3: 'MWI',
    dialCode: '+265',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Malaysia',
      fr: 'Malaisie',
    },
    iso2: 'MY',
    iso3: 'MYS',
    dialCode: '+60',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Maldives',
      fr: 'Maldives',
    },
    iso2: 'MV',
    iso3: 'MDV',
    dialCode: '+960',
    maxDigit: 7,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Mali',
      fr: 'Mali',
    },
    iso2: 'ML',
    iso3: 'MLI',
    dialCode: '+223',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Malta',
      fr: 'Malte',
    },
    iso2: 'MT',
    iso3: 'MLT',
    dialCode: '+356',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Marshall Islands',
      fr: 'Îles Marshall',
    },
    iso2: 'MH',
    iso3: 'MHL',
    dialCode: '+692',
    maxDigit: 7,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Mauritania',
      fr: 'Mauritanie',
    },
    iso2: 'MR',
    iso3: 'MRT',
    dialCode: '+222',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Mauritius',
      fr: 'Île Maurice',
    },
    iso2: 'MU',
    iso3: 'MUS',
    dialCode: '+230',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Mexico',
      fr: 'Mexique',
    },
    iso2: 'MX',
    iso3: 'MEX',
    dialCode: '+52',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Micronesia',
      fr: 'Micronésie',
    },
    iso2: 'FM',
    iso3: 'FSM',
    dialCode: '+691',
    maxDigit: 7,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Moldova',
      fr: 'Moldavie',
    },
    iso2: 'MD',
    iso3: 'MDA',
    dialCode: '+373',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Monaco',
      fr: 'Monaco',
    },
    iso2: 'MC',
    iso3: 'MCO',
    dialCode: '+377',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Mongolia',
      fr: 'Mongolie',
    },
    iso2: 'MN',
    iso3: 'MNG',
    dialCode: '+976',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Montenegro',
      fr: 'Monténégro',
    },
    iso2: 'ME',
    iso3: 'MNE',
    dialCode: '+382',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Morocco',
      fr: 'Maroc',
    },
    iso2: 'MA',
    iso3: 'MAR',
    dialCode: '+212',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Mozambique',
      fr: 'Mozambique',
    },
    iso2: 'MZ',
    iso3: 'MOZ',
    dialCode: '+258',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Namibia',
      fr: 'Namibie',
    },
    iso2: 'NA',
    iso3: 'NAM',
    dialCode: '+264',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Nauru',
      fr: 'Nauru',
    },
    iso2: 'NR',
    iso3: 'NRU',
    dialCode: '+674',
    maxDigit: 7,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Nepal',
      fr: 'Népal',
    },
    iso2: 'NP',
    iso3: 'NPL',
    dialCode: '+977',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Netherlands',
      fr: 'Pays-Bas',
    },
    iso2: 'NL',
    iso3: 'NLD',
    dialCode: '+31',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'New Zealand',
      fr: 'Nouvelle-Zélande',
    },
    iso2: 'NZ',
    iso3: 'NZL',
    dialCode: '+64',
    maxDigit: 9,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Nicaragua',
      fr: 'Nicaragua',
    },
    iso2: 'NI',
    iso3: 'NIC',
    dialCode: '+505',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Niger',
      fr: 'Niger',
    },
    iso2: 'NE',
    iso3: 'NER',
    dialCode: '+227',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Nigeria',
      fr: 'Nigeria',
    },
    iso2: 'NG',
    iso3: 'NGA',
    dialCode: '+234',
    maxDigit: 10,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'North Macedonia',
      fr: 'Macédoine du Nord',
    },
    iso2: 'MK',
    iso3: 'MKD',
    dialCode: '+389',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Norway',
      fr: 'Norvège',
    },
    iso2: 'NO',
    iso3: 'NOR',
    dialCode: '+47',
    maxDigit: 8,
    nddPrefix: '0',
  },
  {
    name: {
      en: 'Oman',
      fr: 'Oman',
    },
    iso2: 'OM',
    iso3: 'OMN',
    dialCode: '+968',
    maxDigit: 8,
    nddPrefix: '0',
  },
];

// export const phoneDialCodeData = [
//   {
//     name: {
//       en: 'Oman',
//       fr: 'Oman',
//     },
//     iso2: 'OM',
//     iso3: 'OMN',
//     dialCode: '+968',
//     maxDigit: 8,
//     nddPrefix: '0',
//   },
//   {
//     name: {
//       en: 'Canada',
//       fr: 'Canada',
//     },
//     iso2: 'CA',
//     iso3: 'CAN',
//     dialCode: '+1',
//     maxDigit: 10,
//     nddPrefix: '1',
//   },
//   {
//     name: {
//       en: 'China',
//       fr: 'Chine',
//     },
//     iso2: 'CN',
//     iso3: 'CHN',
//     dialCode: '+86',
//     maxDigit: 11,
//     nddPrefix: '0',
//   },
//   {
//     name: {
//       en: 'France',
//       fr: 'France',
//     },
//     iso2: 'FR',
//     iso3: 'FRA',
//     dialCode: '+33',
//     maxDigit: 9,
//     nddPrefix: '0',
//   },
//   {
//     name: {
//       en: 'Germany',
//       fr: 'Allemagne',
//     },
//     iso2: 'DE',
//     iso3: 'DEU',
//     dialCode: '+49',
//     maxDigit: 11,
//     nddPrefix: '0',
//   },
//   {
//     name: {
//       en: 'India',
//       fr: 'Inde',
//     },
//     iso2: 'IN',
//     iso3: 'IND',
//     dialCode: '+91',
//     maxDigit: 10,
//     nddPrefix: '0',
//   },
//   {
//     name: {
//       en: 'Japan',
//       fr: 'Japon',
//     },
//     iso2: 'JP',
//     iso3: 'JPN',
//     dialCode: '+81',
//     maxDigit: 10,
//     nddPrefix: '0',
//   },
//   {
//     name: {
//       en: 'Russia',
//       fr: 'Russie',
//     },
//     iso2: 'RU',
//     iso3: 'RUS',
//     dialCode: '+7',
//     maxDigit: 10,
//     nddPrefix: '8',
//   },
//   {
//     name: {
//       en: 'United Kingdom',
//       fr: 'Royaume-Uni',
//     },
//     iso2: 'GB',
//     iso3: 'GBR',
//     dialCode: '+44',
//     maxDigit: 10,
//     nddPrefix: '0',
//   },
//   {
//     name: {
//       en: 'United States',
//       fr: 'États-Unis',
//     },
//     iso2: 'US',
//     iso3: 'USA',
//     dialCode: '+1',
//     maxDigit: 10,
//     nddPrefix: '1',
//   },
// ];

export const defaultPhoneCodeCountry = {
  name: 'Oman',
  iso2: 'OM',
  iso3: 'OMN',
  dialCode: '+968',
  maxDigit: 8,
  nddPrefix: '0',
};
/* phone dial codes */

/* options for change user's native language */
export const languageSelectOptions = {
  en: { value: 'en', label: 'EN' },
  fr: { value: 'fr', label: 'FR' },
};
/* options for change user's native language */
